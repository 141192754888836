.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 64px;
  color: var(--dark-grey);
  margin-bottom: 16px;
  line-height: 1;
}

.subtitle {
  font-size: 16px;
  color: var(--grey);
  margin-bottom: 48px;
  line-height: 1;
}

.action {
  /* heh */
}