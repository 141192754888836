@import url('./variables.css');

.ReactTable.SMETable {
  border: none;
}
.ReactTable.SMETable .rt-head .rt-tr {
  padding: 0 55px;
}

.ReactTable.SMETable .rt-head.-header {
  box-shadow: none;
}

.ReactTable.SMETable .rt-head .rt-th {
  padding: 30px 0 !important;
  border-right: none !important;
  color: var(--dark-grey);
  font-family: var(--table-font-family);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
}

.ReactTable.SMETable .rt-tbody .rt-tr-group {
  border-bottom: none;
}

.ReactTable.SMETable .rt-tbody .rt-tr {
  padding: 0 55px;
  display: flex;
  align-items: center;
}

.ReactTable.SMETable .rt-tbody .rt-tr.-odd {
  background-color: var(--light-grey) !important;
}

.ReactTable.SMETable .rt-tbody .rt-tr .rt-td {
  border-right: none !important;
  padding: 16px;
}
