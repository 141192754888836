@import url('./variables.css');

.content-container {
  padding-top: 71px;
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: auto;
  column-gap: var(--grid-gutter-width);
}

.Content {
  grid-column-start: 2;
  grid-column-end: span 3;
}
