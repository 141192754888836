@import url('../styles/variables.css');

.HeaderLink {
  color: var(--form-section-description-color);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  cursor: pointer;
  transition: var(--transition-duration-short) var(--transition-ease-out);
  font-family: "Montserrat Regular", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.HeaderLink:hover {
  color: var(--orange);
  text-decoration: none;
}

.HeaderLink--active {
  color: var(--orange);
  font-weight: bold;
  font-family: 'Montserrat Bold', sans-serif;
}

.HeaderLink svg{
  margin-right: 11px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
}

.HeaderLink--active svg,.HeaderLink:hover svg{
  fill: #EB7924
}