.container{
    display: flex;
    height: 100vh;
    width: 100%;
}

.form {
    padding: 3% 150px 20px;
    flex-basis: 50%;
    background-color: white;
}

.formIcon {
    margin: 0 auto 20px;
    display: block;
    transform: translateX(18px);
    width: 90px;
}

.formHeader {
    font-family: 'Montserrat Bold', sans-serif;
    color: #3F4C6F;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 32px;
}

.formGroup{
    margin-bottom: 24px;
}

.formInput input:focus{
    outline: none;
    border-color: #eb7924;
}

.formRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 60px;
}

.loginFormForgotPasswordLink{
    font-family: "Montserrat Regular",sans-serif;
    font-size: 14px;
    color: #3F4C6F;
}

.form .formButton{
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    font-weight: bold;
    height: 38px;
    color: white;
}

.image {
    flex-basis: 50%;
    background-color: #EAEEFA;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Login.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.formLink{
    font-size: 14px;
    color: #E8683B;
    font-family: "Montserrat Regular", sans-serif;
    margin-top: 20px;
    justify-content: center;
    display: flex;
}

.formLink:hover{
    text-decoration: none;
}

@media (max-height: 650px) {
    .loginForm {
        height: fit-content;
        min-height: 100%;
    }

    .loginImage {
        position: fixed;
        right: 0;
        width: 50%;
        height: 100%;
    }

    .registerForm {
        height: fit-content;
        margin-left: 50%;
    }

    .registerImage {
        position: fixed;
        left: 0;
        width: 50%;
        height: 100%;
    }
}

@media (min-height: 700px) {
    .form {
        padding-top: 8%;
    }

    .formHeader {
        margin-bottom: 50px;
    }

    .formIcon {
        margin-bottom: 35px;
    }
}