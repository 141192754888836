.Checkbox{
  display: flex;
  align-items: center;
  color: #333;
  font-family: "Roboto Regular",sans-serif;
  font-size: 14px;
}

.Checkbox__check{
  display: none;
}

.Checkbox__label{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid #EAECF0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
  /*  transition: var(--transition-duration-short) var(--transition-ease-out);*/
}

.Checkbox__check:checked + .Checkbox__label{
  border-color: var(--orange);
}

.Checkbox__label::after{
  width: 12px;
  height: 12px;
  background-color: var(--orange);
  border-radius: 3px;
}

.Checkbox__check:checked + .Checkbox__label::after{
  content: '';
}