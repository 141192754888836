.statusCard{
    width: 100%;
    border-radius: 5px;
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;
    color: white;
    line-height: 26px;
}

.statusCardSuccess{
    background-color: green;
    padding: 10px 20px;
    margin: 20px 0;
}

.statusCardError {
    background-color: #E55827;
    padding: 10px 20px;
    margin: 20px 0;
}