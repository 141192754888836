@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.otf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets/fonts/montserrat/Montserrat-Medium.otf");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Italic";
  src: url("./assets/fonts/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'SF NS Display Regular';
  src: url("./assets/fonts/SFNSDisplay.ttf");
}

* {
  margin: 0;
  padding: 0;
}
