.loadingWrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingLogo{
    filter: grayscale(1);
    animation: pulse 1s forwards infinite cubic-bezier(0.53, 0.22, 0.85, 0.58);
    width: 150px;
}

@keyframes pulse {
    0%{
        filter: grayscale(1) brightness(100%);
    }

    50%{
        filter: grayscale(1) brightness(60%);
    }

    100%{
        filter: grayscale(1) brightness(100%);
    }
}

.inline {
    width: 100%;
    height: unset;
    margin-top: 200px;
}