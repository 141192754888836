:root {
  --deep-blue: #1f2537;
  --body-color: #f7f8f9;
  --white: white; /* heh */
  --very-light-grey: #333;
  --light-grey: #fcfcfc;
  --grey: #9fa5b7;
  --dark-grey: #333;
  --darker-grey: #707a94;
  --orange: #eb7924;
  --light-orange: #F8D5C8;
  --dark-orange: #ca6112;
  --teal: #00a2ab;
  --light-teal: #eaeefa;
  --dark-teal: blue;
  --red: red; /* hehe */
  --danger-red: #E55827;

  --main-grey: #212B36;
  --subdued-grey: #637381;

  --primary-color: var(--orange);
  --secondary-color: var(--teal);

  --sidebar-link-color: var(--darker-grey);
  --sidebar-link-active-color: var(--dark-grey);

  --placeholder-text-color: #c2c6d1;
  --checkbox-border-color: var(--placeholder-text-color);

  --page-title-color: var(--darker-grey);

  --grid-gutter-width: 23.27px;
  --grid-max-width: 1120px;
  --grid-num-colums: 12;
  --grid-offfset: 160px;
  --grid-column-width: 72px;

  --grid-breakpoint-xs: 0px;
  --grid-breakpoint-sm: 576px;
  --grid-breakpoint-md: 768px;
  --grid-breakpoint-lg: 992px;
  --grid-breakpoint-xl: 1200px;

  --container-max-width-sm: 540px;
  --container-max-width-md: 720px;
  --container-max-width-lg: 960px;
  --container-max-width-xl: var(--grid-max-width);

  --transition-ease-out: ease-out; /* This might be redefined in the future */
  --transition-duration-short: 0.2s;

  --table-font-family: Roboto, sans-serif;
  --body-font-family: Montserrat, sans-serif;

  --modal-z-index: 10000;

  --card-title-font-size: 16px;
  --card-body-font-size: 14px;
  --page-title-font-size: 18px;
  --form-section-title-font-size: 16px;
  --form-section-description-font-size: 14px;
  --form-section-title-color: #212B36;
  --form-section-description-color: #333;
  --form-section-title-line-height: 20px;
  --form-section-description-line-height: 20px;


  --filter-placeholder-font-size: 14px;
}
