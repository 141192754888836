@import '../../styles/variables';
.AuthPage {
  .PageTitle {
    color: $darkGrey;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 1.33px;
    line-height: 39px;
    text-align: center;
    margin-bottom: 64px;
  }

  .TwoPanedView {
    width: 100vw;
    height: 100vh;
    display: flex;

    .TwoPanedView__image {
      width: 50%;
      height: 100%;
      background-color: $lightTeal;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .TwoPanedView__content {
      width: 50%;
      height: 100%;
      background-color: $white;
      padding: 110px 10%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
  .InputRow {
    margin-bottom: 24px;
  }

  label:not(.is-checkbox-label) {
    color: $darkGrey;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.86px;
    line-height: 15px;
    display: block;
    margin-bottom: 12px;
  }

  input:not([type='checkbox']) {
    padding: 16px 24px;
    border: 2px solid $veryLightGrey;
    border-radius: 5px;
    background-color: $white;
    width: 100%;
    color: $darkGrey;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0.86px;
    line-height: 15px;

    transition: $transition-duration-short $transition-ease-out;

    &:invalid {
      border-color: $red;
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      border-color: $darkGrey;
    }

    &::placeholder {
      color: $grey;
      opacity: 1; /* Firefox */
    }
  }

  input[type='checkbox'] {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    border: 1px solid $checkbox-border-color;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;

    &:checked::after {
      content: '';
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid $darkGrey;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  label.is-checkbox-label {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      margin-right: 12px;
    }
  }

  .BlockButton {
    display: block;
    width: 100%;
    border: none;
    background: $orange;
    padding: 12px 32px;
    text-align: center;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 16px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: $transition-duration-short $transition-ease-out;

    &:hover,
    &:focus {
      outline: none;
      background: darken($orange, 20%);
    }
    &:disabled {
      background: lighten($orange, 20%);
      cursor: not-allowed;
    }
  }
}
