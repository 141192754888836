@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700|Roboto:400,700&display=swap);
:root {
  --deep-blue: #1f2537;
  --body-color: #f7f8f9;
  --white: white; /* heh */
  --very-light-grey: #333;
  --light-grey: #fcfcfc;
  --grey: #9fa5b7;
  --dark-grey: #333;
  --darker-grey: #707a94;
  --orange: #eb7924;
  --light-orange: #F8D5C8;
  --dark-orange: #ca6112;
  --teal: #00a2ab;
  --light-teal: #eaeefa;
  --dark-teal: blue;
  --red: red; /* hehe */
  --danger-red: #E55827;

  --main-grey: #212B36;
  --subdued-grey: #637381;

  --primary-color: var(--orange);
  --secondary-color: var(--teal);

  --sidebar-link-color: var(--darker-grey);
  --sidebar-link-active-color: var(--dark-grey);

  --placeholder-text-color: #c2c6d1;
  --checkbox-border-color: var(--placeholder-text-color);

  --page-title-color: var(--darker-grey);

  --grid-gutter-width: 23.27px;
  --grid-max-width: 1120px;
  --grid-num-colums: 12;
  --grid-offfset: 160px;
  --grid-column-width: 72px;

  --grid-breakpoint-xs: 0px;
  --grid-breakpoint-sm: 576px;
  --grid-breakpoint-md: 768px;
  --grid-breakpoint-lg: 992px;
  --grid-breakpoint-xl: 1200px;

  --container-max-width-sm: 540px;
  --container-max-width-md: 720px;
  --container-max-width-lg: 960px;
  --container-max-width-xl: var(--grid-max-width);

  --transition-ease-out: ease-out; /* This might be redefined in the future */
  --transition-duration-short: 0.2s;

  --table-font-family: Roboto, sans-serif;
  --body-font-family: Montserrat, sans-serif;

  --modal-z-index: 10000;

  --card-title-font-size: 16px;
  --card-body-font-size: 14px;
  --page-title-font-size: 18px;
  --form-section-title-font-size: 16px;
  --form-section-description-font-size: 14px;
  --form-section-title-color: #212B36;
  --form-section-description-color: #333;
  --form-section-title-line-height: 20px;
  --form-section-description-line-height: 20px;


  --filter-placeholder-font-size: 14px;
}

.ReactTable.SMETable {
  border: none;
}
.ReactTable.SMETable .rt-head .rt-tr {
  padding: 0 55px;
}

.ReactTable.SMETable .rt-head.-header {
  box-shadow: none;
}

.ReactTable.SMETable .rt-head .rt-th {
  padding: 30px 0 !important;
  border-right: none !important;
  color: var(--dark-grey);
  font-family: var(--table-font-family);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
}

.ReactTable.SMETable .rt-tbody .rt-tr-group {
  border-bottom: none;
}

.ReactTable.SMETable .rt-tbody .rt-tr {
  padding: 0 55px;
  display: flex;
  align-items: center;
}

.ReactTable.SMETable .rt-tbody .rt-tr.-odd {
  background-color: var(--light-grey) !important;
}

.ReactTable.SMETable .rt-tbody .rt-tr .rt-td {
  border-right: none !important;
  padding: 16px;
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-color);
  color: var(--darker-grey);
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a,
button.is-link {
  font-family: var(--body-font-family);
  border: none;
  background-color: transparent;
  display: inline;
  padding: 0;
  color: var(--orange);
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

a:hover,
a:focus button.is-link:hover,
button.is-link:focus {
  text-decoration: underline;
}

.PageTitle {
  color: var(--darker-grey);
  font-size: 16px;
  letter-spacing: 0.67px;
  line-height: 19px;
  font-weight: normal;
}

.Card {
  border: 1px solid var(--border-color);
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;
}

.Card.Card--primary {
  border-color: var(--border-primary-color);
}

.Spinner {
  width: 16px;
  height: 16px;
  border: 3px solid var(--white);
  border-bottom-color: transparent;
  border-radius: 50%;

  -webkit-animation: spin 0.7s ease-out infinite;

          animation: spin 0.7s ease-out infinite;
}

.Spinner.Spinner__xs {
  width: 12px;
  height: 12px;
}

.Spinner.Spinner__md {
  width: 24px;
  height: 24px;
}
.Spinner.Spinner__large {
  width: 32px;
  height: 32px;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Montserrat Regular";
  src: url(/static/media/Montserrat-Regular.92db9a07.otf);
}

@font-face {
  font-family: "Montserrat Bold";
  src: url(/static/media/Montserrat-Medium.d815b0a2.otf);
}

@font-face {
  font-family: "Roboto Bold";
  src: url(/static/media/Roboto-Bold.ee7b96fa.ttf);
}

@font-face {
  font-family: "Roboto Regular";
  src: url(/static/media/Roboto-Regular.3e1af3ef.ttf);
}

@font-face {
  font-family: "Roboto Italic";
  src: url(/static/media/Roboto-Italic.42bbe4ee.ttf);
}

@font-face {
  font-family: "Roboto Medium";
  src: url(/static/media/Roboto-Medium.d0884059.ttf);
}

@font-face {
  font-family: 'SF NS Display Regular';
  src: url(/static/media/SFNSDisplay.51e09713.ttf);
}

* {
  margin: 0;
  padding: 0;
}

.Login_container__3nxz2{
    display: flex;
    height: 100vh;
    width: 100%;
}

.Login_form__1qaet {
    padding: 3% 150px 20px;
    flex-basis: 50%;
    background-color: white;
}

.Login_formIcon__XgGkr {
    margin: 0 auto 20px;
    display: block;
    transform: translateX(18px);
    width: 90px;
}

.Login_formHeader__3D3jT {
    font-family: 'Montserrat Bold', sans-serif;
    color: #3F4C6F;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 32px;
}

.Login_formGroup__1wX9a{
    margin-bottom: 24px;
}

.Login_formInput__HLi0L input:focus{
    outline: none;
    border-color: #eb7924;
}

.Login_formRow__2CznH{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 60px;
}

.Login_loginFormForgotPasswordLink__ml01i{
    font-family: "Montserrat Regular",sans-serif;
    font-size: 14px;
    color: #3F4C6F;
}

.Login_form__1qaet .Login_formButton__1povc{
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    font-weight: bold;
    height: 38px;
    color: white;
}

.Login_image__1Gb-i {
    flex-basis: 50%;
    background-color: #EAEEFA;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-image: url(/static/media/Login.b6a6dc38.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Login_formLink__2WK3P{
    font-size: 14px;
    color: #E8683B;
    font-family: "Montserrat Regular", sans-serif;
    margin-top: 20px;
    justify-content: center;
    display: flex;
}

.Login_formLink__2WK3P:hover{
    text-decoration: none;
}

@media (max-height: 650px) {
    .Login_loginForm__m4eGt {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-height: 100%;
    }

    .Login_loginImage__2Eb19 {
        position: fixed;
        right: 0;
        width: 50%;
        height: 100%;
    }

    .Login_registerForm__79EYB {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-left: 50%;
    }

    .Login_registerImage__1liGv {
        position: fixed;
        left: 0;
        width: 50%;
        height: 100%;
    }
}

@media (min-height: 700px) {
    .Login_form__1qaet {
        padding-top: 8%;
    }

    .Login_formHeader__3D3jT {
        margin-bottom: 50px;
    }

    .Login_formIcon__XgGkr {
        margin-bottom: 35px;
    }
}

.TextInput:invalid {
  border-color: var(--red);
  outline: none;
  box-shadow: none;
}

.TextInput:focus {
  outline: none;
  border-color: var(--dark-grey);
}

.TextInput__input::-webkit-input-placeholder {
  color: var(--grey);
  /* Firefox applies an opacity to the placeholder by default, get rid of it */
  opacity: 1;
  font-family: "Roboto Regular",sans-serif;
}

.TextInput__input:-ms-input-placeholder {
  color: var(--grey);
  /* Firefox applies an opacity to the placeholder by default, get rid of it */
  opacity: 1;
  font-family: "Roboto Regular",sans-serif;
}

.TextInput__input::-ms-input-placeholder {
  color: var(--grey);
  /* Firefox applies an opacity to the placeholder by default, get rid of it */
  opacity: 1;
  font-family: "Roboto Regular",sans-serif;
}

.TextInput__input::placeholder {
  color: var(--grey);
  /* Firefox applies an opacity to the placeholder by default, get rid of it */
  opacity: 1;
  font-family: "Roboto Regular",sans-serif;
}

.TextInput__label {
  color: var(--dark-grey);
  font-weight: normal;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 12px;
  margin: 0 0 6px;
  display: inline-flex;
}

.TextInput__input {
  padding: 16px 24px;
  border: 1px solid #ffebdb;
  border-radius: 5px;
  background-color: var(--white);
  width: 100%;
  color: var(--dark-grey);
  font-family: var(--body-font-family);
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 15px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
}

.TextInput__input:focus{
  border-color: #eb7924;
  outline: none;
  box-shadow: none;
}

.TextInput__input:disabled{
  background-color: #f7f8f9
}

.TextInput__error{
  font-size: 14px;
  color: var(--danger-red);
  font-family: "Montserrat Regular", sans-serif;
  margin-top: 5px;
  display: inline-flex;
}
.Checkbox{
  display: flex;
  align-items: center;
  color: #333;
  font-family: "Roboto Regular",sans-serif;
  font-size: 14px;
}

.Checkbox__check{
  display: none;
}

.Checkbox__label{
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid #EAECF0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
  /*  transition: var(--transition-duration-short) var(--transition-ease-out);*/
}

.Checkbox__check:checked + .Checkbox__label{
  border-color: var(--orange);
}

.Checkbox__label::after{
  width: 12px;
  height: 12px;
  background-color: var(--orange);
  border-radius: 3px;
}

.Checkbox__check:checked + .Checkbox__label::after{
  content: '';
}
.Button {
  border: none;
  cursor: pointer;
  border-radius: 19px;
  padding: 12px 42px;
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 15px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
  font-family: "Montserrat Bold", sans-serif;
  /* Avoid weird issues with <a> buttons */
  display: inline-block;
}

.Button:hover{
  text-decoration: none;
}

.Button--primary {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: bold;
}

.Button--primary--outline {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.Button--primary:hover,
.Button--primary:focus {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.Button--primary--outline:hover,
.Button--primary--outline:focus {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  background-color: var(--primary-color);
  color: var(--white);
}

.Button--secondary {
  background-color: var(--secondary-color);
  color: var(--white);
  font-weight: bold;
  font-family: "Roboto Regular", sans-serif;
}

.Button--secondary:hover,
.Button--secondary:focus {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.Button--secondary--outline {
  background-color: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.Button--secondary--outline:hover,
.Button--secondary--outline:focus {
  background-color: var(--secondary-color);
  color: var(--white);
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.Button--danger {
  color: var(--danger-red);
  background-color: transparent;
  border: 1px solid var(--danger-red);
}

.Button--danger:hover,.Button--danger:focus {
  background-color: var(--danger-red);
  color: white;
  border-color: transparent;
}

.Button--loader-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button-loader{
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-top-color: #d2cfcf;
  -webkit-animation: rotate 0.55s linear infinite;
          animation: rotate 0.55s linear infinite;
  border-radius: 100%;
  display: inline-flex;
}

.Button--primary--outline .Button-loader{
  border: 2px solid #eb7924;
  border-top-color: #ff8a26;
}

.Button--secondary--outline .Button-loader{
  border: 2px solid #009bab;
  border-top-color: #8cd5cc;
}

.Button--danger--outline .Button-loader{
  border: 2px solid #df4e1b;
  border-top-color: #ff6329;
}

@-webkit-keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.StatusCard_statusCard__1LUxg{
    width: 100%;
    border-radius: 5px;
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;
    color: white;
    line-height: 26px;
}

.StatusCard_statusCardSuccess__12AzU{
    background-color: green;
    padding: 10px 20px;
    margin: 20px 0;
}

.StatusCard_statusCardError__pPzbI {
    background-color: #E55827;
    padding: 10px 20px;
    margin: 20px 0;
}
.ForgotPassword_container__32hV6{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.ForgotPassword_form__26Xby{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 50px 100px;
    width: 440px;
    background-color: white;
    box-shadow: 0 3px 8px 0 rgba(112,120,135,0.24);
    border-radius: 10px;
    height: 475px;
}

.ForgotPassword_formInput__2YDj2{
    margin-bottom: 44px;
    width: 100%;
}

.ForgotPassword_formInput__2YDj2 input{
    border-color: #FFEBDB;
}

.ForgotPassword_formInput__2YDj2 input:focus{
    border-color: #eb7924;
    outline: none;
}

.ForgotPassword_formButton__18pac{
    margin-bottom: 70px;
}

.ForgotPassword_formLink__24NJ1{
    color: #00A2AB;
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.ForgotPassword_forgotSuccess__3U7Es{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 50px 100px;
    width: 440px;
    background-color: white;
    box-shadow: 0 3px 8px 0 rgba(112,120,135,0.24);
    border-radius: 10px;
    height: 475px;
}

.ForgotPassword_forgotSuccessHeader__1Ts0-{
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
    color: #3f4c6f;
    margin-bottom: 30px;
}

.ForgotPassword_forgotSuccessText__1Efw6{
    font-size: 14px;
    line-height: 24px;
    font-family: "Montserrat Regular", sans-serif;
    color: #333;
    margin-bottom: 50px;
    text-align: center;
}
/* hehe */
.AuthPage .PageTitle {
  color: #3f4c6f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.33px;
  line-height: 39px;
  text-align: center;
  margin-bottom: 64px; }

.AuthPage .TwoPanedView {
  width: 100vw;
  height: 100vh;
  display: flex; }
  .AuthPage .TwoPanedView .TwoPanedView__image {
    width: 50%;
    height: 100%;
    background-color: #eaeefa; }
    @media (max-width: 991.98px) {
      .AuthPage .TwoPanedView .TwoPanedView__image {
        display: none; } }
  .AuthPage .TwoPanedView .TwoPanedView__content {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    padding: 110px 10%; }
    @media (max-width: 991.98px) {
      .AuthPage .TwoPanedView .TwoPanedView__content {
        width: 100%; } }

.AuthPage .InputRow {
  margin-bottom: 24px; }

.AuthPage label:not(.is-checkbox-label) {
  color: #3f4c6f;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.86px;
  line-height: 15px;
  display: block;
  margin-bottom: 12px; }

.AuthPage input:not([type='checkbox']) {
  padding: 16px 24px;
  border: 2px solid #eaecf0;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  color: #3f4c6f;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 15px;
  transition: 0.2s ease-out; }
  .AuthPage input:not([type='checkbox']):invalid {
    border-color: red;
    outline: none;
    box-shadow: none; }
  .AuthPage input:not([type='checkbox']):focus {
    outline: none;
    border-color: #3f4c6f; }
  .AuthPage input:not([type='checkbox'])::-webkit-input-placeholder {
    color: #9fa5b7;
    opacity: 1;
    /* Firefox */ }
  .AuthPage input:not([type='checkbox']):-ms-input-placeholder {
    color: #9fa5b7;
    opacity: 1;
    /* Firefox */ }
  .AuthPage input:not([type='checkbox'])::-ms-input-placeholder {
    color: #9fa5b7;
    opacity: 1;
    /* Firefox */ }
  .AuthPage input:not([type='checkbox'])::placeholder {
    color: #9fa5b7;
    opacity: 1;
    /* Firefox */ }

.AuthPage input[type='checkbox'] {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: #ffffff;
  border: 1px solid #c2c6d1;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center; }
  .AuthPage input[type='checkbox']:checked::after {
    content: '';
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #3f4c6f;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg); }

.AuthPage label.is-checkbox-label {
  display: flex;
  align-items: center; }
  .AuthPage label.is-checkbox-label input[type='checkbox'] {
    margin-right: 12px; }

.AuthPage .BlockButton {
  display: block;
  width: 100%;
  border: none;
  background: #eb7924;
  padding: 12px 32px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.86px;
  line-height: 16px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out; }
  .AuthPage .BlockButton:hover, .AuthPage .BlockButton:focus {
    outline: none;
    background: #9b4a0e; }
  .AuthPage .BlockButton:disabled {
    background: #f4b281;
    cursor: not-allowed; }

.ResetPassword_form__1QiTB{
    padding-top: 60px;
}
.content-container {
  padding-top: 71px;
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: auto;
  grid-column-gap: var(--grid-gutter-width);
  -webkit-column-gap: var(--grid-gutter-width);
          column-gap: var(--grid-gutter-width);
}

.Content {
  grid-column-start: 2;
  grid-column-end: span 3;
}

.Header .Header__top {
  height: 92px;
  background: var(--deep-blue);
}

.Header__logo {
  width: 68px;
}

.Header .Header__top .Header__right {
  display: flex;
  align-items: center;
  color: var(--very-light-grey);

  font-size: 14px;
  letter-spacing: 0.58px;
  line-height: 18px;
}

.Header .Header__bottom {
  height: 84px;
  background: var(--white);
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
}

.Header__bottom .container,
.Header__top .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header__right{
  position: relative;
}

.Header__button{
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  font-family: Montserrat,sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.Header__profile-links{
  box-shadow: 0 10px 24px 0 rgba(29,42,68,0.12);
  background-color: white;
  border-radius: 5px;
  padding: 34px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  transform: scale(0);
  transition: 300ms;
  transform-origin: top right;
}

.Header__profile-links--active{
  transform: scale(1);
  max-height: 140px;
}

.Header__profile-link:hover{
  text-decoration: none;
}

.Header__profile-link{
  font-family: "Montserrat Regular", sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Header__profile-link-icon{
  margin-right: 33px;
  fill: #9FA5B7;
  width: 15px;
}


.Header__container-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width: 1000px;
  margin: auto;
}

.Header__link--grid{
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.Header__notifications {
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px);
  position: absolute;
  z-index: 10;
  border: 1px solid transparent;
  left: -160px;
  border-radius: 5px;
  overflow: hidden;
  transition: 300ms;
  transform: scaleY(0);
  transform-origin: top left;
}

.Header__notifications--active {
  max-height: 500px;
  border-color: #e2e6e4;
  padding-top: 20px;
  transform: scaleY(1);
  overflow-y: auto;
}

.Header__notifications > *{
  opacity: 0;
}

.Header__notifications--active > * {
  -webkit-animation-name: slide-in;
          animation-name: slide-in;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.Header__notifications::-webkit-scrollbar {
  width: 8px;
}

.Header__notifications::-webkit-scrollbar-thumb {
  background-color: #222e32;
  border-radius: 4px;
}

.Header__notifications-clear {
  background-color: transparent;
  border: none;
  font-family: "Roboto Regular", sans-serif;
  font-size: 14px;
  margin: 0 20px 20px auto;
  color: #9fa5b7;
  cursor: pointer;
}

.Header__notification {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-column-gap: 10px;
  font-family: "Montserrat Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 20px;
  font-weight: 100;
  color: #3F4C6F;
}

.Header__notification:nth-of-type(odd) {
  background-color: #F8F9FB;
}

.Header__notification:last-of-type {
  border: none;
}

.Header__notification span {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.Header__notification-date {
  align-items: flex-start;
  grid-column-gap: 10px;
  font-size: 12px;
  color: #9fa5b7;
}

.Header__notification-mark-read {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7ff;
  margin: auto 0;
  padding: 2px;
}

.Header__notifications-wrapper {
  position: relative;
  height: 24px;
}

.Header__notifications-button {
  background-color: transparent;
  border: none;
  margin: 0 1.6rem 0 10px;
  outline: none;
  cursor: pointer;
}

.Header__notification-link {
  font-weight: inherit;
  color: inherit;
}

.Header__notification-link:hover {
  text-decoration: none;
}

.Header__notifications-empty {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #9fa5b7;
}

@-webkit-keyframes slide-in {
  0%{
    transform: translateX(30px);
    opacity: 0;
  }

  70%{
    opacity: 1;
  }

  100%{
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-in {
  0%{
    transform: translateX(30px);
    opacity: 0;
  }

  70%{
    opacity: 1;
  }

  100%{
    transform: translateX(0px);
    opacity: 1;
  }
}

.HeaderLink {
  color: var(--form-section-description-color);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  cursor: pointer;
  transition: var(--transition-duration-short) var(--transition-ease-out);
  font-family: "Montserrat Regular", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.HeaderLink:hover {
  color: var(--orange);
  text-decoration: none;
}

.HeaderLink--active {
  color: var(--orange);
  font-weight: bold;
  font-family: 'Montserrat Bold', sans-serif;
}

.HeaderLink svg{
  margin-right: 11px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
}

.HeaderLink--active svg,.HeaderLink:hover svg{
  fill: #EB7924
}
/* hehe */
.Avatar {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  object-fit: cover; }
  .Avatar.Avatar--bordered {
    border: 1px solid #EAECF0; }

.Loading_loadingWrapper__2Mo82{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loading_loadingLogo__29nVU{
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    -webkit-animation: Loading_pulse__3KgAd 1s forwards infinite cubic-bezier(0.53, 0.22, 0.85, 0.58);
            animation: Loading_pulse__3KgAd 1s forwards infinite cubic-bezier(0.53, 0.22, 0.85, 0.58);
    width: 150px;
}

@-webkit-keyframes Loading_pulse__3KgAd {
    0%{
        -webkit-filter: grayscale(1) brightness(100%);
                filter: grayscale(1) brightness(100%);
    }

    50%{
        -webkit-filter: grayscale(1) brightness(60%);
                filter: grayscale(1) brightness(60%);
    }

    100%{
        -webkit-filter: grayscale(1) brightness(100%);
                filter: grayscale(1) brightness(100%);
    }
}

@keyframes Loading_pulse__3KgAd {
    0%{
        -webkit-filter: grayscale(1) brightness(100%);
                filter: grayscale(1) brightness(100%);
    }

    50%{
        -webkit-filter: grayscale(1) brightness(60%);
                filter: grayscale(1) brightness(60%);
    }

    100%{
        -webkit-filter: grayscale(1) brightness(100%);
                filter: grayscale(1) brightness(100%);
    }
}

.Loading_inline__1YVs2 {
    width: 100%;
    height: unset;
    margin-top: 200px;
}
.LMSErrorBoundary_container__2FKiv {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LMSErrorBoundary_title__2I7XO {
  font-size: 64px;
  color: var(--dark-grey);
  margin-bottom: 16px;
  line-height: 1;
}

.LMSErrorBoundary_subtitle__2UGjB {
  font-size: 16px;
  color: var(--grey);
  margin-bottom: 48px;
  line-height: 1;
}

.LMSErrorBoundary_action__PKoGG {
  /* heh */
}
.Error404_container__P6KJx {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Error404_title__oqqgz {
  font-size: 64px;
  color: var(--dark-grey);
  margin-bottom: 16px;
  line-height: 1;
}

.Error404_subtitle__3Yi7j {
  font-size: 16px;
  color: var(--grey);
  margin-bottom: 48px;
  line-height: 1;
}

.Error404_action__7i6Rv {
  /* heh */
}
