.container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 50px 100px;
    width: 440px;
    background-color: white;
    box-shadow: 0 3px 8px 0 rgba(112,120,135,0.24);
    border-radius: 10px;
    height: 475px;
}

.formInput{
    margin-bottom: 44px;
    width: 100%;
}

.formInput input{
    border-color: #FFEBDB;
}

.formInput input:focus{
    border-color: #eb7924;
    outline: none;
}

.formButton{
    margin-bottom: 70px;
}

.formLink{
    color: #00A2AB;
    font-family: "Montserrat Regular", sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.forgotSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 50px 100px;
    width: 440px;
    background-color: white;
    box-shadow: 0 3px 8px 0 rgba(112,120,135,0.24);
    border-radius: 10px;
    height: 475px;
}

.forgotSuccessHeader{
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
    color: #3f4c6f;
    margin-bottom: 30px;
}

.forgotSuccessText{
    font-size: 14px;
    line-height: 24px;
    font-family: "Montserrat Regular", sans-serif;
    color: #333;
    margin-bottom: 50px;
    text-align: center;
}