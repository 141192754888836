@import "../styles/variables";

.Avatar {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  object-fit: cover;
  
  &.Avatar--bordered {
    border: 1px solid #EAECF0;
  }
}