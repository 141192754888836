@import "../styles/variables.css";

.Button {
  border: none;
  cursor: pointer;
  border-radius: 19px;
  padding: 12px 42px;
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 15px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
  font-family: "Montserrat Bold", sans-serif;
  /* Avoid weird issues with <a> buttons */
  display: inline-block;
}

.Button:hover{
  text-decoration: none;
}

.Button--primary {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: bold;
}

.Button--primary--outline {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.Button--primary:hover,
.Button--primary:focus {
  filter: brightness(90%);
}

.Button--primary--outline:hover,
.Button--primary--outline:focus {
  filter: brightness(100%);
  background-color: var(--primary-color);
  color: var(--white);
}

.Button--secondary {
  background-color: var(--secondary-color);
  color: var(--white);
  font-weight: bold;
  font-family: "Roboto Regular", sans-serif;
}

.Button--secondary:hover,
.Button--secondary:focus {
  filter: brightness(80%);
}

.Button--secondary--outline {
  background-color: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.Button--secondary--outline:hover,
.Button--secondary--outline:focus {
  background-color: var(--secondary-color);
  color: var(--white);
  filter: brightness(100%);
}

.Button--danger {
  color: var(--danger-red);
  background-color: transparent;
  border: 1px solid var(--danger-red);
}

.Button--danger:hover,.Button--danger:focus {
  background-color: var(--danger-red);
  color: white;
  border-color: transparent;
}

.Button--loader-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button-loader{
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-top-color: #d2cfcf;
  animation: rotate 0.55s linear infinite;
  border-radius: 100%;
  display: inline-flex;
}

.Button--primary--outline .Button-loader{
  border: 2px solid #eb7924;
  border-top-color: #ff8a26;
}

.Button--secondary--outline .Button-loader{
  border: 2px solid #009bab;
  border-top-color: #8cd5cc;
}

.Button--danger--outline .Button-loader{
  border: 2px solid #df4e1b;
  border-top-color: #ff6329;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}