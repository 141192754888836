@import url('./styles/variables.css');

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700|Roboto:400,700&display=swap');

@import url('../node_modules/bootstrap/dist/css/bootstrap-grid.css');

@import url('./styles/react-table.css');

body {
  margin: 0;
  font-family: var(--body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-color);
  color: var(--darker-grey);
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a,
button.is-link {
  font-family: var(--body-font-family);
  border: none;
  background-color: transparent;
  display: inline;
  padding: 0;
  color: var(--orange);
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

a:hover,
a:focus button.is-link:hover,
button.is-link:focus {
  text-decoration: underline;
}

.PageTitle {
  color: var(--darker-grey);
  font-size: 16px;
  letter-spacing: 0.67px;
  line-height: 19px;
  font-weight: normal;
}

.Card {
  border: 1px solid var(--border-color);
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;
}

.Card.Card--primary {
  border-color: var(--border-primary-color);
}

.Spinner {
  width: 16px;
  height: 16px;
  border: 3px solid var(--white);
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: spin 0.7s ease-out infinite;
}

.Spinner.Spinner__xs {
  width: 12px;
  height: 12px;
}

.Spinner.Spinner__md {
  width: 24px;
  height: 24px;
}
.Spinner.Spinner__large {
  width: 32px;
  height: 32px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
