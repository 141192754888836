@import url('../styles/variables.css');
@import url('../styles/grid.css');

.Header .Header__top {
  height: 92px;
  background: var(--deep-blue);
}

.Header__logo {
  width: 68px;
}

.Header .Header__top .Header__right {
  display: flex;
  align-items: center;
  color: var(--very-light-grey);

  font-size: 14px;
  letter-spacing: 0.58px;
  line-height: 18px;
}

.Header .Header__bottom {
  height: 84px;
  background: var(--white);
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
}

.Header__bottom .container,
.Header__top .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header__right{
  position: relative;
}

.Header__button{
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  font-family: Montserrat,sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.Header__profile-links{
  box-shadow: 0 10px 24px 0 rgba(29,42,68,0.12);
  background-color: white;
  border-radius: 5px;
  padding: 34px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  transform: scale(0);
  transition: 300ms;
  transform-origin: top right;
}

.Header__profile-links--active{
  transform: scale(1);
  max-height: 140px;
}

.Header__profile-link:hover{
  text-decoration: none;
}

.Header__profile-link{
  font-family: "Montserrat Regular", sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Header__profile-link-icon{
  margin-right: 33px;
  fill: #9FA5B7;
  width: 15px;
}


.Header__container-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width: 1000px;
  margin: auto;
}

.Header__link--grid{
  text-align: center;
  width: fit-content;
  margin: auto;
}

.Header__notifications {
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px);
  position: absolute;
  z-index: 10;
  border: 1px solid transparent;
  left: -160px;
  border-radius: 5px;
  overflow: hidden;
  transition: 300ms;
  transform: scaleY(0);
  transform-origin: top left;
}

.Header__notifications--active {
  max-height: 500px;
  border-color: #e2e6e4;
  padding-top: 20px;
  transform: scaleY(1);
  overflow-y: auto;
}

.Header__notifications > *{
  opacity: 0;
}

.Header__notifications--active > * {
  animation-name: slide-in;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-delay: 300ms;
}

.Header__notifications::-webkit-scrollbar {
  width: 8px;
}

.Header__notifications::-webkit-scrollbar-thumb {
  background-color: #222e32;
  border-radius: 4px;
}

.Header__notifications-clear {
  background-color: transparent;
  border: none;
  font-family: "Roboto Regular", sans-serif;
  font-size: 14px;
  margin: 0 20px 20px auto;
  color: #9fa5b7;
  cursor: pointer;
}

.Header__notification {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-column-gap: 10px;
  font-family: "Montserrat Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 20px;
  font-weight: 100;
  color: #3F4C6F;
}

.Header__notification:nth-of-type(odd) {
  background-color: #F8F9FB;
}

.Header__notification:last-of-type {
  border: none;
}

.Header__notification span {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.Header__notification-date {
  align-items: flex-start;
  grid-column-gap: 10px;
  font-size: 12px;
  color: #9fa5b7;
}

.Header__notification-mark-read {
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7ff;
  margin: auto 0;
  padding: 2px;
}

.Header__notifications-wrapper {
  position: relative;
  height: 24px;
}

.Header__notifications-button {
  background-color: transparent;
  border: none;
  margin: 0 1.6rem 0 10px;
  outline: none;
  cursor: pointer;
}

.Header__notification-link {
  font-weight: inherit;
  color: inherit;
}

.Header__notification-link:hover {
  text-decoration: none;
}

.Header__notifications-empty {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #9fa5b7;
}

@keyframes slide-in {
  0%{
    transform: translateX(30px);
    opacity: 0;
  }

  70%{
    opacity: 1;
  }

  100%{
    transform: translateX(0px);
    opacity: 1;
  }
}
