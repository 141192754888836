
.TextInput:invalid {
  border-color: var(--red);
  outline: none;
  box-shadow: none;
}

.TextInput:focus {
  outline: none;
  border-color: var(--dark-grey);
}

.TextInput__input::placeholder {
  color: var(--grey);
  /* Firefox applies an opacity to the placeholder by default, get rid of it */
  opacity: 1;
  font-family: "Roboto Regular",sans-serif;
}

.TextInput__label {
  color: var(--dark-grey);
  font-weight: normal;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 12px;
  margin: 0 0 6px;
  display: inline-flex;
}

.TextInput__input {
  padding: 16px 24px;
  border: 1px solid #ffebdb;
  border-radius: 5px;
  background-color: var(--white);
  width: 100%;
  color: var(--dark-grey);
  font-family: var(--body-font-family);
  font-size: 14px;
  letter-spacing: 0.86px;
  line-height: 15px;
  transition: var(--transition-duration-short) var(--transition-ease-out);
}

.TextInput__input:focus{
  border-color: #eb7924;
  outline: none;
  box-shadow: none;
}

.TextInput__input:disabled{
  background-color: #f7f8f9
}

.TextInput__error{
  font-size: 14px;
  color: var(--danger-red);
  font-family: "Montserrat Regular", sans-serif;
  margin-top: 5px;
  display: inline-flex;
}