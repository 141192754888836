$deep-blue: #1f2537;
$body-color: #f7f8f9;
$white: #ffffff;
$veryLightGrey: #eaecf0;
$lightGrey: #fcfcfc;
$grey: #9fa5b7;
$darkerGrey: #707a94;
$darkGrey: #3f4c6f;
$orange: #eb7924;
$teal: #00a2ab;
$lightTeal: #eaeefa;
$red: red; /* hehe */

$primary-color: $orange;
$secondary-color: $teal;

$border-color: #eaecf0;
$border-primary-color: #ffe4cf;

$sidebar-link-color: $darkerGrey;
$sidebar-link-active-color: $darkGrey;

$placeholder-text-color: #c2c6d1;
$checkbox-border-color: #c2c6d1;

$page-title-color: $darkerGrey;

$grid-gutter-width: 23.27px;
$grid-max-width: 1120px;
$grid-num-columns: 12;
$grid-offset: 160px;
$grid-column-width: 72px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: $grid-max-width,
);

$transition-ease-out: ease-out;
$transition-duration-short: 0.2s;

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
